<template>
	<div class="tag-list">
		<div class="left-title">{{title}}</div>
		<div class="right-box">
			<div class="label-button" :class="val.active?'selected':null" @click="handleSelect(val,index)" v-for="(val,index) in tags">
				<span v-if="val.val" class="color-box" :style="{backgroundColor:val.val}"></span> {{val.name}}
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:'',
	data(){
		return{
			
		}
	},
	props:{
		tags:{
			type:Array,
			default:[]
		},
		title:{
			type:String,
			default:''
		}
	},
	methods:{
		handleSelect(val,index){
			if(index == 0){
				val.active = true
				this.tags.map(function(item,n){
					if(n>0){
						item.active = false;
					}
				})
			}else{
				val.active = !val.active;
				this.tags[0].active = true;
				let _this = this;
				this.tags.map(function(item,n){
					if(n>0 && item.active){
						_this.tags[0].active = false;
					}
				})
			}
		}
	}
}
</script>

<style lang="scss">
.tag-list{
	margin-bottom: 1.2rem;
	
	.left-title{
		display: inline-block;
		font-weight: bolder;
		font-size: 15px;
		padding: 0.5rem 1.5rem;
		width: 100px;
		text-align: right;
	}
	
	.right-box{
		display: inline-block;
		width: calc(100% - 100px);
		vertical-align: top;
		padding: 0;
	}
	
	.label-button{
		display: inline-block;
		padding: 0.5rem 1rem;
		cursor:pointer;
		border-radius: 3px;
		margin-right: 0.5rem;
	}
	
	.color-box{
		width: 10px;
		height: 10px;
		border-radius: 10px;
		display: inline-block;
	}
	
	.selected{
		background: #21c3a9;
		color: #fff;
	}
}
</style>
