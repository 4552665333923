<template>
	<div class="materialList">
		<div class="box" v-for="(val,key) in data" v-if="val.coverpic" @click.stop="clickHandle(val)">
			<div class="img-bg">
				<img class="img" :src="val.coverpic">
			</div>
			<div class="bottom">
				<h3 class="title">{{val.name}}</h3>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:"materialList",
	props:{
		data:{
			type:Array,
			default:()=>[]
		}
	},
	methods:{
		clickHandle(val){
			this.$emit('clickHandle',val)
		}
	}
}
</script>

<style lang="scss">
.materialList{
	.box{
		display: inline-block;
		margin: 1rem;
		width: calc(20% - 2rem);
		height: 30rem;
		background: #fff;
		vertical-align: top;
		border-radius: 0.5rem;
		cursor: pointer;
		
		.img-bg{
			border-radius: 0.5rem 0.5rem 0 0;
			background: rgba(234,234,234,1);
		}
		.img{
			width: 100%;
			margin-top: 0.5rem;
		}
		.bottom{
			padding: 0.5rem;
		}
		.title{
			font-weight: bolder;
		}
	}
}
</style>
