<template>
	<div class="pageBg" :class="[isShow?'show':'hide']">
		<div class="leftBox" @click.stop="hidePage"></div>
		<div class="pageBox">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	name:'',
	props:{
		isShow:Boolean,
		default:false
	},
	data(){
		return{
			
		}
	},
	methods:{
		hidePage(){
			this.$emit('hidePage');
		}
	}
}
</script>

<style>
	.pageBg{
		background: rgba(0,0,0,0.4);
		height: 100%;
		width: 100%;
		position: absolute;
		left: 100%;
		top: 0;
	}
	
	.pageBox{
		width: calc(100% - 30rem);
		height: 100%;
		position: absolute;
		right: 0;
		background: #fff;
		display: inline-flex;
	}
	
	.leftBox{
		width: 30rem;
		height: 100%;
		display: inline-flex;
	}
	
	.show{
		animation: move 1.5s forwards;
	}
	
	.hide{
		animation: move2 1s forwards;
	}
	
	/* 定义动画-右滑入 */
	@keyframes move{
		from{
			left: 100%;
		}
		to{
			left: 0;
		}
	}
	
	/* 定义动画-右滑出 */
	@keyframes move2{
		from{
			left: 0;
		}
		to{
			left: 100%;
		}
	}
</style>
