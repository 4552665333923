<template>
    <el-card class="left-menu">
        <div class="iconfont icon-sheji menu-item" @click="changeMenu(1)" title="设计图">
            <div class="menu-item-active iconfont icon-youjiantou1" v-if="menuType==1">

            </div>
        </div>
        <div class="iconfont icon-a-3D menu-item" @click="changeMenu(2)" title="3D模型">
            <div class="menu-item-active iconfont icon-youjiantou1" v-if="menuType==2">

            </div>
        </div>
        <div class="iconfont icon-tupian menu-item" @click="changeMenu(3)" title="实物图">
            <div class="menu-item-active iconfont icon-youjiantou1" v-if="menuType==3">

            </div>
        </div>
        <div class="menu-item add-btn" @click="toShowBtn">
            <div class="iconfont icon-ic_add">

            </div>
        </div>
        <transition
            name="sec-fade"
            enter-active-class="fade-enter-active"
            leave-active-class="fade-leave-active"
        >
            <div class=" menu-item create-btn" v-show="showBtn" @click="toDesign">
                <span>设计</span>
            </div>
        </transition>
        <transition
            name="three-fade"
            enter-active-class="fade-enter-active"
            leave-active-class="fade-leave-active"
        >
            <div class=" menu-item create-btn"  v-show="showBtn" @click="toCreate">
                <span>3D</span>
            </div>
        </transition>
    </el-card>
</template>

<script>
    export default {
        name: "homeLeftMenu",
        data(){
            return{
                menuType:1,
                showBtn:false
            }
        },
        methods:{
            toShowBtn(){
                this.showBtn=!this.showBtn
            },
            toDesign(){
                this.$router.push({
                    name:'draw',
                    query:{
                        type:1,
                        id:''
                    },
                })
            },
            toCreate(){
                this.$emit('toUploadThreeD')
            },
            changeMenu(type){
                this.menuType=type
                this.$emit('changeMenu',type)
            },
        }
    }
</script>

<style  lang="scss">
    .left-menu{
        width: 60px;
        height: calc(100% - 60px);
        background-color: #fff;
        border-left: 1px solid #dcdcdc;
        border-radius: 0;
        position: fixed;
        left: 0;
        top: 60px;
        z-index: 101;
        padding: 12px;
        box-sizing: border-box;
        .el-card__body {
            padding: 0!important;
        }
        .menu-item{
            width: 30px;
            height: 30px;
            font-size: 30px;
            margin: 20px auto 0 auto;
            cursor: pointer;
            position: relative;
            color: #333;
            .menu-item-active{
                position: absolute;
                right: -12px;
                color: #999;
                font-size: 12px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .icon-tupian{
            font-size: 26px;
            line-height: 30px;
        }
        .add-btn{
            border-radius: 50%;
            background-color: #333333;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
        }
        .create-btn{
            border-radius: 50%;
            background-color: #333;

            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: 600;
        }
        .fade-enter-active {
            transition: all .3s ease;
        }
        .fade-leave-active {
            transition: all .3s ease;
        }
        .sec-fade-enter,.sec-fade-leave-to {
            transform: translateY(-50px);
            opacity: 0;
        }
        .three-fade-enter,.three-fade-leave-to {
            transform: translateY(-100px);
            opacity: 0;
        }
    }
</style>
