<template>
	<div class="materialTag">
		<tag :tags="tags" title="适用产品"></tag>
		<tag :tags="materialsType" title="材料类型"></tag>
		<tag :tags="colors"  title="色系"></tag>
		<tag :tags="textures" title="纹理"></tag>
		<tag :tags="use" title="功能"></tag>
		<tag :tags="deal" title="后处理"></tag>
	</div>
</template>

<script>
import Tag from "@/components/material/Tag";
export default {
	name:'',
	components:{
		Tag
	},
	props:{
		tags:{
			type:Array,
			default:[
				{name:'全部',active:true},
				{name:'鞋子',active:false},
				{name:'服装',active:false},
				{name:'箱包',active:false},
				{name:'家具',active:false},
				{name:'家纺',active:false},
				{name:'其它',active:false},
			]
		},
		colors:{
			type:Array,
			default:[
				{
					name:'全部',
					val:'',
					active:true
				},
				{
					name:'红色',
					val:'red',
					active:false
				},
				{
					name:'橙色',
					val:'orange',
					active:false
				},
				{
					name:'黄色',
					val:'yellow',
					active:false
				},
				{
					name:'绿色',
					val:'green',
					active:false
				},
				{
					name:'青色',
					val:'cyan',
					active:false
				},
				{
					name:'蓝色',
					val:'blue',
					active:false
				},
				{
					name:'紫色',
					val:'purple',
					active:false
				},
				{
					name:'黑色',
					val:'black',
					active:false
				},
				{
					name:'灰色',
					val:'grey',
					active:false
				},
				{
					name:'白色',
					val:'white',
					active:false
				},
			]
		},
		materialsType:{
			type:Array,
			default:[
				{name:'全部',active:true},
				{name:'纺织品',active:false},
				{name:'真皮',active:false},
				{name:'人造革',active:false},
				{name:'其它',active:false},
			]
		},
		textures:{
			type:Array,
			default:[
				{name:'全部',active:true},
				{name:'条纹',active:false},
				{name:'镂空',active:false},
				{name:'方格',active:false},
			]
		},
		use:{
			type:Array,
			default:[
				{name:'全部',active:true},
				{name:'轻量的',active:false},
				{name:'持久耐用',active:false},
				{name:'软垫/缓冲垫层',active:false},
				{name:'反光',active:false},
				{name:'有弹性的',active:false},
				{name:'防水',active:false},
				{name:'防泼水',active:false},
				{name:'透明',active:false},
				{name:'可模塑的',active:false},
				{name:'抗菌',active:false},
				{name:'驱虫剂',active:false},
				{name:'紫外线阻滞剂',active:false},
				{name:'持久性防泼水',active:false},
				{name:'透气',active:false},
				{name:'去污',active:false},
				{name:'防污',active:false},
				{name:'弹性',active:false},
				{name:'轻量',active:false},
				{name:'超软',active:false},
				{name:'热压',active:false},
				{name:'高周波热切',active:false},
				{name:'其他功能',active:false},
			]
		},
		deal:{
			type:Array,
			default:[
				{name:'全部',active:true},
				{name:'印花',active:false},
				{name:'染色',active:false},
				{name:'固色',active:false},
				{name:'抗静电',active:false},
				{name:'抗起球',active:false},
				{name:'防羽绒',active:false},
				{name:'防霉',active:false},
				{name:'免烫',active:false},
				{name:'阻燃',active:false},
				{name:'防水',active:false},
				{name:'防泼水',active:false},
				{name:'洗水',active:false},
				{name:'防皱',active:false},
				{name:'防晒',active:false},
				{name:'烫金',active:false},
				{name:'复合',active:false},
				{name:'压花压绉',active:false},
				{name:'植绒',active:false},
				{name:'拉毛磨毛',active:false},
				{name:'砂洗',active:false},
				{name:'烂花（烧花）',active:false},
				{name:'切割',active:false},
				{name:'起皱',active:false},
				{name:'轧泡',active:false},
				{name:'间棉绗缝',active:false},
				{name:'网板印',active:false},
				{name:'压花',active:false},
				{name:'热转印',active:false},
				{name:'数码打印',active:false},
				{name:'激光打印',active:false},
				{name:'发泡胶',active:false},
				{name:'去污',active:false},
				{name:'防污',active:false},
				{name:'涂层',active:false},
				{name:'其他后处理',active:false},
			]
		}
	},
	data(){
		return{
			
		}
	},
	methods:{
		handleSelect(val,index){
			if(index == 0){
				val.active = true
				this.tags.map(function(item,n){
					if(n>0){
						item.active = false;
					}
				})
			}else{
				val.active = !val.active;
				this.tags[0].active = true;
				let _this = this;
				this.tags.map(function(item,n){
					if(n>0 && item.active){
						_this.tags[0].active = false;
					}
				})
			}
		}
	}
}
</script>

<style lang="scss">
.materialTag{
	width: 100%;
	background: #fff;
	min-height: 20rem;
	border-radius: 1rem;
	padding: 2rem 4rem;
	
	.tag-list{
		margin-bottom: 1.2rem;
	}
	
	.left-title{
		display: inline-block;
		font-weight: bolder;
		font-size: 15px;
		padding: 0.5rem 1.5rem;
		width: 100px;
		text-align: right;
	}
	
	.right-box{
		display: inline-block;
		width: calc(100% - 100px);
		vertical-align: top;
		padding: 0;
	}
	
	.label-button{
		display: inline-block;
		padding: 0.5rem 1rem;
		cursor:pointer;
		border-radius: 3px;
		margin-right: 0.5rem;
	}
	
	.color-box{
		width: 10px;
		height: 10px;
		border-radius: 10px;
		display: inline-block;
	}
	
	.selected{
		background: #21c3a9;
		color: #fff;
	}
}
</style>
