<template>
  <div class="home">
	<div class="list-table-header">
		<a-dropdown class="dropDown">
		  <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
		    <a-button icon="plus" type="primary"></a-button>
		  </a>
		  <a-menu slot="overlay">
		    <a-menu-item v-for="(menu, idx) in addType" :key="idx" @click="handleAdd(menu.material)">
		      <a href="javascript:;">{{ $t(menu.label) }}</a>
		    </a-menu-item>
		  </a-menu>
		</a-dropdown>
		<div class="list-search">
		  <el-input
		    class="list-search-input"
			v-model="searchName"
			@keyup.enter.native="searchDesign"
		  ></el-input>
		  <el-upload
		    action=""
		    accept=".jpg,.jpeg,.png,.JPG,.PNG"
		    :show-file-list="false"
		    ref="upload"
			:http-request="toSearchImage"
		  >
		    <i class="iconfont icon-zhaoxiangji image-search-icon"></i>
		  </el-upload>
		  <i
		    class="iconfont icon-fangdajing list-search-icon"
			@click="searchDesign"
		  ></i>
		</div>
	</div>
    <MaterialTag></MaterialTag>
	<div class="table-box" :style="{ 'max-height': pageHeight + 'px' }">
      <div>
		<materialList
		ref="materialList"
		:data="tableData"
		@clickHandle="clickHandle">
		</materialList>
        <div class="pagination" v-if="tableData.length > 0 && !isInAiSearch">
          <el-pagination
            background
            big
            :total="total"
            :current-page="page"
            :page-size="page_size"
            layout="prev, pager, next"
            @current-change="currentChange"
          />
        </div>
      </div>
    </div>
	<!-- <slidePage class="slidePage" v-if="pageShow" :isShow="pageShow" @hidePage="hidePage()"></slidePage> -->
  </div>
</template>

<script>
import homeLeftMenu from "../../components/homeLeftMenu/homeLeftMenu";
import materialTable from "../../components/materialTable/materialTable";
import materialList from "../../components/materialTable/materialList";
import createDialog from "../../components/createDialog/createDialog";
import selectThreeDialog from "../../components/selectThreeDialog/selectThreeDialog";
import createProduct from "../../components/createProduct/createProduct";
import slidePage from "../../components/commonPage/slidePage";
import WorkSpace from "../../components/WorkSpace";
import materialsEdit from "../../components/commonPage/materialsEdit";
import uploadCos from "@/utils/upload_to_cos";
import { mapState, mapMutations,mapActions } from "vuex";
import MaterialEditor from "@/components/material/MaterialEditor";
import MaterialTag from "@/components/material/MaterialTag";
import {
  materials,
  materialsConfig,
  materialsData,
} from "@/utils/babylonjs/meshData";
let that;
let checkDetail = false;
export default {
  components: {
    materialTable,
    createDialog,
    selectThreeDialog,
    createProduct,
	materialList,
	slidePage,
	WorkSpace,
	materialsEdit,
	MaterialEditor,
	MaterialTag
  },
  beforeRouteEnter(to, from, next) {
    if (from.name == "productDetail") {
      checkDetail = true;
    }
    next();
  },
  data() {
    return {
      page: 1,
      page_size: 15,
      tableData: [],
      total: 0,
	  isInAiSearch:false,
	  searchName:'',
	  addType: [
	    { label: "createMetalMaterial", material: "metal" },
	    { label: "createLeatherMaterial", material: "leather" },
	    { label: "createClothMaterial", material: "cloth" },
	    { label: "createFurMaterial", material: "fur" },
	    { label: "createGlassMaterial", material: "glass" },
	    { label: "createSoleMaterial", material: "sole" },
	  ],
    };
  },
  computed: {
    ...mapState("jiyang/account", ["isUserLogin"]),
    ...mapState("jiyang/user", ["info", "userRole"]),
  },
  watch: {
    $route: {
      handler() {
        if (!checkDetail) {
          this.init();
        }

        if (this.$route.name == "Home" && !this.isFirstEnter) {
          this.menuType = this.$route.query.type;
          this.getTableData();
        }
      },
      deep: true,
    },
  },
  created() {
    that = this;
    if (this.$route.query.type) {
      this.menuType = this.$route.query.type;
    } else {
      this.menuType = -1;
    }
    this.isFirstEnter = true;
    this.toCheckLogin();
    this.tableHeight = document.documentElement.clientHeight - 150;
    this.pageHeight = document.documentElement.clientHeight - 150;
    if (this.isFirstEnter) {
      this.getTableData();
      this.isFirstEnter = false;
    }
  },
  mounted() {
    //    监听屏幕
    window.onresize = () => {
      return (() => {
        that.tableHeight = document.documentElement.clientHeight - 150;
        that.pageHeight = document.documentElement.clientHeight - 150;
      })();
    };
  },
  activated() {
    this.setMenu(2);
  },
  methods: {
    ...mapMutations("jiyang/account", ["toCheckLogin"]),
    ...mapMutations("jiyang/menu", ["setMenu"]),
	...mapActions('jiyang/material',["getMaterialList"]),
	...mapMutations('jiyang/material',[
	  "SET_SEARCH",
	  "SET_SEARCH_TYPE",
	]),
    //上传刷新列表
    createProcut() {
      this.getTableData();
    },
    //关闭筛选
    closeFilter() {
      //   this.init();
      this.$refs.materialTable.showSearchBox = false;
    },
    //筛选
    searchDesign(form) {
      this.page = 1;
	  this.SET_SEARCH_TYPE(2);
	  this.SET_SEARCH(this.searchName);
      this.getTableData();
    },
    //ai搜索图片
    async toSearchImage(file) {
      let that = this;
      let files = file.file;
      let check = await this.checkImage(files);
      if (!check) {
        this.$message.error("上传图片信息有误");
        return false;
      }
      var reader = new FileReader();
      // 转base64
      reader.onload = function (e) {
        that.imageUrl = e.target.result; // 将图片路径赋值给src
      };
      reader.readAsDataURL(files);
      this.$emit("toSearchImage", files);
    },
    //获取列表
    getTableData() {
      let loading = this.openLoading();
      if (this.isInAiSearch) {
        var forms = new FormData();
        forms.append("image", this.img_file);
        forms.append("page", this.page);
        forms.append("page_size", this.page_size);
        if (this.menuType > 0) {
          forms.append("type", this.menuType);
        }
        this.$api.getDesignImageList(forms).then((res) => {
          that.tableData = res.bomInfo;
          that.total = _.get(res, "ai_info.result_num", 0);
          loading.close();
        });
      } else {
        let data = {
          page: this.page,
          page_size: this.page_size,
        };
        let search = {};
        if (this.menuType > 0) {
          search = { ...this.search_form, ...{ type: this.menuType } };
        } else {
          search = this.search_form;
        }
        data = { ...data, ...{ search } };
        // this.$api.getProductList(data).then((res) => {
        //   that.tableData = res.data;
        //   that.total = res.total;
        //   loading.close();
        // });
		
		this.getMaterialList(data).then((res) => {
			that.tableData = res.data
			that.total = res.total;
			 loading.close();
		});
      }
    },
    //设计分页
    currentChange(arg) {
      this.page = arg; // 当前页：arg
      this.getTableData();
    },
    init() {
      this.page = 1;
    },
	clickHandle(val){
		localStorage.setItem('materialData',JSON.stringify(val));
		this.$router.push({
		  name: 'materialsEdit'
		});
	},
	hidePage(){
		this.pageShow = false;
	},
	handleAdd(type){
		this.$router.push({
		  name: 'materialsEdit',
		  query:materialsData[type]
		});
	}
  },
};
</script>
<style lang="scss">
@import "index.scss";
	.slidePage{
		position: absolute;
		width: 100%;
		height: calc(100% - 52px);
		top: 52px;
	}
	
	.dropDown{
		position: absolute;
		left: 10px;
	}
</style>
