<template>
  <div class="create-product-dialog">
    <div class="create-btn" @click="toShowDialog">
      <i class="iconfont icon-ic_add"></i>
    </div>
    <productDialog
      v-if="dialogCreateVisible"
      :createForm="createForm"
      @closeModal="closeModal"
      @createProcut="createProcut"
    ></productDialog>
  </div>
</template>

<script>
import productDialog from "../productDialog/productDialog";
export default {
  name: "createProduct",
  components: {
    productDialog,
  },
  data() {
    return {
      dialogCreateVisible: false,
      createForm: {
        name: "",
        style_number: "",
        model_name: "",
        type: 1,
        file_info: {},
        price: "",
        intro: "",
        is_public: 1,
        desc: [""],
        color: "",
        size: "",
      },
    };
  },
  methods: {
    closeModal() {
      this.dialogCreateVisible = false;
    },
    //    显示弹窗
    toShowDialog() {
      this.createForm = {
        name: "",
        style_number: "",
        model_name: "",
        type: 1,
        file_info: {},
        price: "",
        intro: "",
        is_public: 1,
        desc: [""],
      };
      // this.imageUrl=''
      // this.img_file=''
      // this.three_d_file=''
      // this.fileList=[]
      // this.img_file_list=[]
      this.dialogCreateVisible = true;
    },
    createProcut() {
      this.dialogCreateVisible = false;
      this.$emit("createProcut");
    },
  },
};
</script>

<style lang="scss">
.create-btn {
  width: 50px;
  height: 50px;

  cursor: pointer;
  position: fixed;
  left: 30px;
  bottom: 30px;
  border-radius: 50%;
  background-color: #333333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  .icon-ic_add {
    font-size: 28px;
  }
}
</style>
